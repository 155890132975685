import React from 'react';
import './App.css';
import WelcomePage from './pages/welcomepage';
import PrivacyPolicy from './pages/PrivacyPolicy';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

function App() {
  const HOMEPAGE = process.env.REACT_APP_HOMEPAGE;
  const IS_DEV = parseInt(process.env.REACT_APP_IS_DEV);
  
  var basename = IS_DEV ? '/' : HOMEPAGE;

  return (
    <div className="App">
      <Router basename={basename}>
        <Routes>
          <Route path="/" element={<WelcomePage />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
