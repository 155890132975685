import React, { useEffect } from 'react';

const PrivacyPolicy = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div style={{ padding: '20px' }}>
            <h1>Privacy Policy</h1>
            <p style={{ textAlign: 'left' }}>This privacy policy governs your use of the software application for mobile devices that was created by MetaverseXR.</p>
            <p style={{ textAlign: 'left' }}>This app does not collect any data or need any special permissions outside of Google’s own policies within Google Play Framework.</p>
            <p style={{ textAlign: 'left' }}>When you register with us and use the Application, you generally provide (a) your name, email address, age, user name, password and other registration information; (b) information you provide us when you contact us for help;</p>
            <p style={{ textAlign: 'left' }}>Does the Application collect precise real time location information of the device?<br />
            This Application does not collect precise information about the location of your mobile device.</p>
            <p style={{ textAlign: 'left' }}>Do third parties see and/or have access to information obtained by the Application?<br />
            This Application does not allow 3rd parties to see and/or have access to information.</p>
            <p style={{ textAlign: 'left' }}>What are my opt-out rights?<br />
            You can stop all collection of information by the Application easily by uninstalling the Application. You may use the standard uninstall processes as may be available as part of your mobile device or via the mobile application marketplace or network.</p>
            <p style={{ textAlign: 'left' }}>Security<br />
            We are concerned about safeguarding the confidentiality of your information. We provide physical, electronic, and procedural safeguards to protect information we process and maintain.</p>
            <p style={{ textAlign: 'left' }}>Changes<br />
            This Privacy Policy may be updated from time to time for any reason. You are advised to consult this Privacy Policy regularly for any changes, as continued use is deemed approval of all changes.</p>
            <p style={{ textAlign: 'left' }}>Your Consent<br />
            By using the Application, you are consenting to our processing of your information as set forth in this Privacy Policy now and as amended by us.</p>
            <p style={{ textAlign: 'left' }}>Contact us<br />
            If you have any questions regarding privacy while using the Application, or have questions about our practices, please contact us via email at metaversexrth@gmail.com</p>
        </div>
    );
};

export default PrivacyPolicy;
