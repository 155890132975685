import React, { useState, useEffect } from 'react';
import backgroundImage from '../assets/bg.jpg';
import logoMotorway from '../assets/icon.png';
import QRweb from '../assets/qr_google.png';
import QRapp from '../assets/qr_app.png';
import btn_wep from '../assets/button_google.png';
import btn_app from '../assets/button_app.png';
import npc_g from '../assets/npc2_2.png';
import npc_b from '../assets/npc3_3.png';
// import { Link } from 'react-router-dom';
// import movement from '../assets/howto_movement.png';
// import Modal from 'react-modal';

const WelcomePage = () => {
    const [isMobile, setIsMobile] = useState(false);
    const [isIpad, setIsIpad] = useState(false);
    // const [isModalOpen, setIsModalOpen] = useState(false);

    useEffect(() => {
        // ฟังก์ชันตรวจสอบขนาดหน้าจอ
        const checkScreenSize = () => {
            setIsMobile(window.innerWidth <= 700); // ถ้าขนาดหน้าจอ <= 768px ถือว่าเป็นมือถือ
            setIsIpad(window.innerWidth > 700 && window.innerWidth <= 1366);
        };

        // ตรวจสอบขนาดหน้าจอเมื่อโหลด component
        checkScreenSize();

        // ตรวจสอบขนาดหน้าจอเมื่อมีการเปลี่ยนแปลงขนาดหน้าจอ
        window.addEventListener('resize', checkScreenSize);

        // ล้าง event listener เมื่อ component ถูก unmount
        return () => {
            window.removeEventListener('resize', checkScreenSize);
        };
    }, []);
    // const openUploadModal = () => {
    //     setIsModalOpen(true);
    // };

    // const closeUploadModal = () => {
    //     setIsModalOpen(false);
    // };

    const styles = {
        container: {
            position: 'relative',
            width: '100%',
            minHeight: '100vh',
            overflow: 'hidden',
            
        },
        background: {
            position: 'absolute',
            width: '100%',
            height: '100%',
            top: '0',
            left: '0',
            backgroundImage: `url(${backgroundImage})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            zIndex: '-1',
        },
        content: {
            position: 'relative',
            margin: '0 auto',
            maxWidth: '1154px',
            padding : isIpad ? '42px 42px 100px 42px' : (isMobile ? '42px 30px 100px ' : '42px 0'),
            textAlign: 'center',
            marginBottom:isMobile ?'10vh':'25vh'
        },
    };

    return (
        <div style={styles.container}>
            <div style={styles.background}></div>
            <div style={styles.content}>
                <img src={logoMotorway} alt="logoMotorway"
                    style={{
                        width: '100%',
                        maxWidth: isIpad ? '550px' : (isMobile ? '290.64px' : '691.69px'),
                        height: 'auto'
                    }} />
                <h2 style={{ fontSize: isMobile ? '40px' : '64px', color: '#0C2428',marginBottom: isMobile ?'24px':'36px', marginTop:isMobile ?'24px': '36px' }}>ยินดีต้อนรับ</h2>
                <p 
                    style={{ 
                        fontSize: isMobile ? '16px' : '40px', 
                        marginBottom: isMobile ? '24px' : '36px', 
                        marginTop: isMobile ? '24px' : '36px',
                        whiteSpace: isMobile || isIpad ? 'normal' : 'nowrap'
                    }}
                >
                    ขอเชิญทุกท่านร่วมสัมผัสประสบการณ์ 
                    {isMobile || isIpad ? <br /> : null}
                    สุดตื่นเต้นบนโลกเสมือนจริงแห่งใหม่ที่ 
                </p>
                <p style={{ fontSize: isMobile ? '16px' : '40px', color: '#EFBC53', marginBottom: isMobile ?'24px':'36px', marginTop:isMobile ?'24px': '36px' }}> <strong>Motorway Verse</strong></p>
                <p
                    style={{
                        fontSize: isMobile ? '16px' : '40px',
                        marginBottom: isMobile ? '24px' : '36px',
                        marginTop: isMobile ? '24px' : '36px',
                        
                    }}
                    >
                    ที่จะพาคุณไปทำความรู้จักกับ
                    {isMobile || isIpad ? <br /> : null}มอเตอร์เวย์มากขึ้น
                </p> 
                <div style={{  
                    width:'100%',
                    backgroundColor: '#044A561A',
                    borderRadius: '36px',
                    boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)', // 25% ของสีดำ
                    paddingTop: '2px',
                    paddingBottom: '2px',
                    backdropFilter: 'blur(10px)', // ใช้ backdropFilter แทน filter
                }}>
                    <p style={{
                        fontSize: isMobile ? '16px' : '40px',
                        color: '#000', // เปลี่ยนสีข้อความให้ชัดเจน
                        filter: 'none', // ลบ blur จากข้อความ
                    }}>
                        <strong>📌 ระยะเวลาการแข่งขันเกมส์</strong> <br />
                        27 กันยายน ถึง 12 ตุลาคม 2567 <br />
                        <strong>📌 ช่วงประกาศผลการแข่งขันเกมส์</strong> <br />
                        15 ตุลาคม 2567 <br />
                        <strong>📌 ระยะเวลาตอบแบบสอบถาม</strong> <br />
                        21 ถึง 25 ตุลาคม 2567 <br />
                        <strong>📌 ช่วงประกาศผล</strong> <br />
                        <strong>การตอบแบบสอบถาม</strong> <br />
                        29 ตุลาคม 2567
                    </p>
                    <p style={{
                        fontSize: isMobile ? '10px' : '25px',
                        color: '#000', // เปลี่ยนสีข้อความให้ชัดเจน
                        filter: 'none', // ลบ blur จากข้อความ
                    }}>
                        สามารถร่วมสนุกได้ตั้งแต่ วันที่ 20 กันยายน 2567 <br />
                        ระบบจะทำการลบคะแนนของกิจกรรมใน <br />
                        วันที่ 26 กันยายน เวลา 23.59 น. เพื่อเริ่มกิจกรรม
                    </p>
                </div>
         
                <div style={{
                    display: 'flex', 
                    justifyContent: 'center', 
                    alignItems: 'center',
                    width: '100%',
                }}>
                    <button
                        onClick={() => window.location.href = 'https://www.motorway-verse.go.th/verse/index.html'}
                        style={{
                            backgroundColor: '#044A56',
                            borderRadius: isMobile ? '18px' : '36px',
                            width: '100%',
                            maxWidth: isMobile ? '398px' : '1154px',
                            height: 'auto',
                            padding: '16px 57px',
                            border: 'none',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                            textAlign: 'center',
                            color: '#FFFFFF',
                            cursor: 'pointer',
                            marginBottom: isMobile ? '24px' : '36px',
                            marginTop: isMobile ? '24px' : '36px'
                        }}disabled={isIpad || isMobile}
                    >
                        <p style={{ 
                            fontSize: isMobile ? '19px' : '40px', 
                            marginBottom: '0px',
                            marginTop:'0px',
                            whiteSpace:'nowrap'
                            }}>
                        เข้าสู่ <strong>Motorway Verse</strong>  โดย PC
                        </p>
                        <p style={{ 
                            fontSize: isMobile ? '12px' : '20px',
                            whiteSpace: 'nowrap',
                            marginBottom: '0px',
                            marginTop: '0px',
                        }}>
                            (ผ่าน Web Browser) 
                            <br />
                            <span style={{ textDecoration: 'underline', }}>
                                https://www.motorway-verse.go.th/verse/index.html
                            </span>
                        </p>
                    </button>
                </div>

                <hr style={{ border:isMobile ?'1px solid #044A56':  '2px solid #044A56', marginBottom: isMobile ?'24px':'36px',width:'100%',maxWidth:isMobile ?'380.59px':'1154px' }} />
                <p style={{ fontSize:isMobile ?'24px': '40px', color: '#044A56', marginBottom:isMobile ?'24px': '36px', marginTop:isMobile ?'24px': '36px' }}>หรือดาวน์โหลดได้ที่</p>
                <div style={{ display: 'flex', justifyContent: 'center', gap: '10px' }}>
                    <div>
                        <a href="https://play.google.com/store/apps/details?id=com.MetaverseXR.MotorwayVerse&pli=1">
                            <img src={btn_wep} alt="btn_wep"
                                style={{
                                    width: '100%',
                                    maxWidth: isIpad ? '250px' :(isMobile ?'187.77px':'307.12px'),
                                    height: 'auto',
                                    marginBottom: '36px',
                                }}
                            />
                        </a>
                        <img src={QRweb} alt="qrweb"
                            style={{
                                width: '100%',
                                maxWidth: isIpad ? '250px' :(isMobile ?'187.77px':'307.12px'),
                                height: 'auto',
                            }}
                        />
                    </div>
                    <div>
                        <a href="https://apps.apple.com/app/motorway-verse/id6670756831">
                            <img src={btn_app} alt="btn_app"
                                style={{
                                    width: '100%',
                                    maxWidth: isIpad ? '250px' :(isMobile ?'187.77px':'307.12px'),
                                    height: 'auto',
                                    marginBottom: '36px',
                                }}
                            />
                        </a>
                        <img src={QRapp} alt="qrapp"
                            style={{
                                width: '100%',
                                maxWidth: isIpad ? '250px' :(isMobile ?'187.77px':'307.12px'),
                                height: 'auto',
                            }}
                        />
                    </div>
                </div>
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between', padding: '0' }}>
                <img src={npc_g} alt="npc_g"
                    style={{
                        position: 'absolute',
                        bottom: '0',
                        width: '100%',
                        maxWidth: isIpad ? '400px' : (isMobile ? '187.77px' : '540.18px'),
                        height: 'auto',
                        zIndex: -1
                    }}
                />
                <div style={{
                    position: 'absolute',
                    bottom: isIpad ? '5%' : (isMobile ? '9%' : '6%'), // ปรับตำแหน่งตามต้องการ
                    left: '50%',
                    transform: 'translate(-50%, 50%)',
                    zIndex: 1,
                    padding: '10px',
                    borderRadius: '5px',
                }}>
                    <a href="https://motorway.mxrth.co/privacy-policy" target="_blank" rel="noopener noreferrer" style={{ color: '#044A56', fontSize: isIpad ? '25px' :(isMobile ?'16px':'40px') }}>Privacy Policy</a>
                    <br />
                    <a href="https://motorway.mxrth.co/privacy-policy" target="_blank" rel="noopener noreferrer" style={{ fontSize: isIpad ? '20px' :(isMobile ?'12px':'35px'),whiteSpace:'nowrap'}}>(https://motorway.mxrth.co/privacy-policy)</a>
                    {/* <Link to="/privacy-policy" target="_blank" rel="noopener noreferrer" style={{ color: '#044A56' }}>Privacy Policy</Link> */}
                </div>
                <img src={npc_b} alt="npc_b"
                    style={{
                        position: 'absolute',
                        bottom: '0',
                        right: '0',
                        width: '100%',
                        maxWidth: isIpad ? '400px' : (isMobile ? '187.77px' : '540.18px'),
                        height: 'auto',
                        zIndex: -1
                    }}
                />
            </div>
            {/* <div style={{ display: 'flex', padding: '0', justifyContent: 'flex-end' }}>
                <div style={{
                    position: 'absolute',
                    top: isIpad ? '0' : (isMobile ? '0' : '0'), // ปรับตำแหน่งตามต้องการ
                    right: '0', // เปลี่ยนจาก 'left' เป็น 'right'
                    transform: 'translate(0, 50%)', // ปรับ transform ให้ไม่ใช้งาน translate ทางแนวนอน
                    zIndex: 1,
                    borderRadius: '5px',
                    fontSize: isMobile ? '16px' : '40px',
                }}>
                    <img src={movement} alt="movement_ht" 
                        style={{
                            width: '100%',
                            maxWidth: isIpad ? '250px' : (isMobile ? '150px' : '367px'),
                            cursor:'pointer'
                        }} 
                        onClick={() => openUploadModal()}    
                    />
                </div>
            </div>
            <Modal isOpen={isModalOpen} onRequestClose={closeUploadModal}
                style={{
                    
                }}
            >

            </Modal> */}
        </div>
    );
};

export default WelcomePage;
